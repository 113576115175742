<template>
  <div class="cart-page">
    <MetaManager
      title="Cart"
      description="Review your cart items and proceed to checkout."
    />
    <h1>Cart</h1>
    <div class="cart-container">
      <div class="cart-items">
        <div v-if="checkoutCartItems.length > 0">
          <div class="cart-item" v-for="(item, index) in checkoutCartItems" :key="index">
            <div class="cart-item-image">
              <img :src="Array.isArray(item.image_link) ? item.image_link[0] : item.image_link || 'https://via.placeholder.com/100'" alt="Product Image" />
            </div>
            <div class="cart-item-details">
              <h3 class="cart-item-name">{{ item.name }}</h3>
              <div class="cart-item-info">
                <span class="cart-item-price"><s>${{ item.originalPrice }}</s></span>
                <span class="cart-item-discount">${{ calculateDiscountedPrice(item.originalPrice, item.discount) }}</span>
                <span class="cart-item-discount-percentage">{{ formatPercentage(item.discount) }}% off</span>
                <div class="cart-item-qty">
                  <label>Qty: {{item.quantity}}</label>
                </div>
              </div>
              <div class="cart-item-actions">
                <button class="remove-button" @click="removeFromCart(index)">Remove</button>
                <button class="fine-print-button" @click="viewFinePrint(item)">Fine Print</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="empty-cart-message">Your cart is empty.</p>
        </div>
      </div>
      <div class="cart-summary">
        <h2>Summary</h2>
        <div class="summary-item">
          <span>Subtotal</span>
          <span>${{ subtotalCost }}</span>
        </div>
        <div class="summary-total">
          <span>Total</span>
          <span>${{ subtotalCost }}</span>
        </div>
        <button class="checkout-button" @click="checkout">Go To Checkout</button>
        <button class="continue-shopping" @click="continueShopping">Continue Shopping</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MetaManager from '../../components/MetaManager.vue';
export default {
  name: 'CartPage',
  components: {
    MetaManager,
  },
  data() {
    return {
      checkoutCartItems: JSON.parse(localStorage.getItem('checkoutCartItems'))?.map(item => ({ ...item, quantity: item.quantity || 1 })) || [],
      isLoggedIn: !!localStorage.getItem('token'),
      token: localStorage.getItem('token')
    };
  },
  computed: {
    subtotalCost() {
      return this.checkoutCartItems.reduce((total, item) => total + this.calculateDiscountedPrice(item.originalPrice, item.discount) * item.quantity, 0).toFixed(2);
    },
    taxCost() {
      return (this.subtotalCost * 0.065).toFixed(2);
    },
    totalCost() {
      return (parseFloat(this.subtotalCost) + parseFloat(this.taxCost)).toFixed(2);
    }
  },
  methods: {
    calculateDiscountedPrice(originalPrice, discount) {
      return (originalPrice * (1 - discount / 100)).toFixed(2);
    },
    formatPercentage(percentage) {
      return parseFloat(percentage).toFixed(2);
    },
    async removeFromCart(index) {
      const itemToRemove = this.checkoutCartItems[index];
      
      // If user is logged in, remove from database
      if (this.isLoggedIn) {
        const user = JSON.parse(localStorage.getItem('user'));
        const userId = user ? user.id : null;

        try {
          await axios.put(
            `${process.env.VUE_APP_API_URL}/api/shoppingCart/remove-from-cart/${userId}`,
            {
              giftCardId: itemToRemove.giftCardId,
              name: itemToRemove.name 
            },
            {
              headers: { 'Authorization': `Bearer ${this.token}` }
            }
          );
        } catch (error) {
          console.error('Error removing from cart on server:', error);
        }
      }

      // Remove from local state and storage
      this.checkoutCartItems.splice(index, 1);
      localStorage.setItem('checkoutCartItems', JSON.stringify(this.checkoutCartItems));
      
      // Dispatch event to update cart UI elsewhere in the application
      window.dispatchEvent(new CustomEvent('update-checkout-cart', { 
        detail: this.checkoutCartItems 
      }));
    },
    updateQuantity() {
      localStorage.setItem('checkoutCartItems', JSON.stringify(this.checkoutCartItems));
    },
    continueShopping() {
      this.$router.push('/gift-cards');
    },
    viewFinePrint(item) {
      if (item.giftCardId) {
        // Navigate to the gift card page
        this.$router.push(`/gift-card/${item.giftCardId}`).then(() => {
          // After navigation is complete, wait a short time for the page to render
          setTimeout(() => {
            // Find the fine print element and scroll to it
            const finePrintElement = document.getElementById('fine-print');
            if (finePrintElement) {
              finePrintElement.scrollIntoView({ behavior: 'smooth' });
            } else {
              // If element not found by ID, try to find it by other means (like a class or data attribute)
              const finePrintSection = document.querySelector('.fine-print-section');
              if (finePrintSection) {
                finePrintSection.scrollIntoView({ behavior: 'smooth' });
              }
            }
          }, 500); // Adjust timeout as needed for page rendering
        });
      } else {
        console.error('No gift card ID found for this item');
      }
    },
    checkout() {
      const token = localStorage.getItem('token');
      if (!token) {
        this.$router.push('/login-to-checkout');
      } else {
        this.$router.push('/checkout');
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>
.cart-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 2em;
}

.cart-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cart-items {
  flex: 2;
}

.cart-item {
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
  flex-wrap: wrap;
}

.cart-item-image img {
  width: 100px;
  height: auto;
  border-radius: 8px;
  margin-right: 20px;
}

.cart-item-details {
  flex: 1;
  margin-left: 20px;
}

.cart-item-name {
  margin: 0 0 10px;
  font-size: 1.2em;
  color: #333;
}

.cart-item-info {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.cart-item-price {
  text-decoration: line-through;
  color: #888;
  margin-right: 10px;
}

.cart-item-discount {
  color: green; /* Dark Green */
  font-weight: bold;
  margin-right: 10px;
}

.cart-item-discount-percentage {
  color: green; /* Accent Green */
  font-weight: bold;
  margin-right: 10px;
}

.cart-item-qty {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.cart-item-qty label {
  margin-right: 5px;
}

.cart-item-qty select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.cart-item-actions {
  display: flex;
  margin-top: 10px;
}

.remove-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 10px;
  margin-right: 10px;
}

.remove-button:hover {
  background-color: #d32f2f;
}

.fine-print-button {
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 10px;
}

.fine-print-button:hover {
  background-color: #0b7dda;
}

.cart-summary {
  flex: 1;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 20px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.summary-total {
  font-weight: bold;
  font-size: 1.2em;
  color: #333;
  margin-top: 10px;
}

.checkout-button, .continue-shopping {
  width: 100%;
  padding: 15px;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.checkout-button {
  background-color: #4C6B30; /* Dark Green */
}

.checkout-button:hover {
  background-color: #6E9F49; /* Accent Green */
}

.continue-shopping {
  background-color: #4C6B30;
}

.continue-shopping:hover {
  background-color: #6E9F49;
}

.empty-cart-message {
  text-align: center;
  font-size: 1.2em;
  color: #999;
}

@media(max-width: 1024px){
  .cart-page{
    padding-bottom: 75px;
  }
}

@media (max-width: 768px) {
  .cart-container {
    flex-direction: column;
  }

  .cart-summary {
    margin-top: 20px;
  }

  .cart-item-details {
    margin-left: 0;
  }

  .cart-item-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-item-qty {
    margin-top: 10px;
  }

  .cart-item-actions {
    flex-direction: column;
    width: 100%;
  }

  .remove-button, .fine-print-button {
    margin-top: 5px;
    width: 100%;
    margin-right: 0;
  }
}
</style>